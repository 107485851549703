<!--** View Section **-->
<template>
<div id="iftar-menu">
    <Header></Header>
    <div class="content-wrapper">
      <div class="breadcrumb-wrap bg-f br-1">
        <div class="overlay bg-black op-9"></div>
        <img :src="shape1" alt="Image" class="br-shape-1" />
        <img :src="shape2" alt="Image" class="br-shape-2" />
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="breadcrumb-title">
                <h2>Iftar Menu</h2>
                <ul class="breadcrumb-menu list-style">
                  <li><a href="">Home</a></li>
                  <li>Iftar Menu</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="product-details-wrap pb-100">
      <div class="container">
        <div class="row pt-100">
          <div class="col-lg-12 text-center">   
            <img :src="menu" alt="Image" class="br-shape-1 center" />
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
</div>
</template>


<!--** Logic Section **-->
<script>
//  Import Section  //
import Header from "@/views/layouts/Header.vue";
import Footer from "@/views/layouts/Footer.vue";
export default {
  //  My Route  //
  name: "iftarmenu",

  //  Components  //
  components: {
    Header,
    Footer
  },

  //  Init  //
  mounted() {},

  //  Variables  //
  data() {
    return {
        shape1: require("@/assets/img/shape-1.png"),
        shape2: require("@/assets/img/shape-2.png"),
        menu: require("@/assets/img/menu/iftar-menu.jpg"),
        activeClass: "",
    };
  },

  //  Functions  //
  methods: {},
};
</script>

<!--** Style Section **-->
<style lang="scss" scoped>
</style>
